<template>
  <div>
    <v-container class="pb-4">
    <h1>Producto</h1>
      <v-row>
          <v-col>
              <v-card  elevation="0">
                  <v-img
                      class="white--text align-end"
                      height="400px"
                      :src="product.image"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  >
                      <v-card-title>{{product.name}}</v-card-title>
                  </v-img>

                      <v-btn color="orange" @click="setProductInCart(product)"> Agregar al carrito <i class="fas fa-shopping-cart"></i></v-btn>
              </v-card>
          </v-col>
          <v-col>
              <div>Nombre producto: {{product.name}}</div>
              <div>Descripcion: {{product.description}}</div>
              <div>Cantidad disponible: {{product.stok}}</div>
              <div>Precio: ${{formatPrice(product.price)}}</div>
          </v-col>
      </v-row>
    </v-container> 
  </div>
</template>
<script>
import { mapState,mapActions } from "vuex";

export default {
  name: "Categories",
  data() {
    return {
    };
  },
  computed: {
    ...mapState(["product"]),
  },
  methods: {
    ...mapActions(["setProduct", "setProductInCart"]),
    setLineInNameProduct(string) {
      return string.replace("-", " ");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  mounted() {
    this.setProduct(this.setLineInNameProduct(this.$route.params.product));
  },
};
</script>